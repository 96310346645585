import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

// Utils
import { validateEmail } from '../../helpers/utils';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Reopen from '../../components/Reopen';
import List from './list';
import AddPlayer from './add_player';

// const statusLabels = {
//   init: 'Ongoing',
//   deal: 'Deal',
//   nodeal: 'Abandoned',
// };

class Players extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      pair_modal: false,
      new_players: [],
    };

    // Bindings
    this.addPairModal = this.addPairModal.bind(this);
    this.handleNewPlayers = this.handleNewPlayers.bind(this);
    this.submitNewPlayers = this.submitNewPlayers.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  addPairModal() {
    this.setState({ pair_modal: !this.state.pair_modal, new_players: [] });
  }

  handleNewPlayers(newPlayers) {
    this.setState({ new_players: newPlayers });
  }

  submitNewPlayers() {
    const { game } = this.props;
    this.props.dispatch(
      gameActions.addPlayers(game.data._id, this.state.new_players)
    );
    this.addPairModal();
    this.setState({ new_players: [] });
  }

  render() {
    const { user, game } = this.props;

    let players = game.data.players || [];
    const groups = game.data.groups || [];

    // const zephyrRole = p.players.find( pl => pl.rindex === 1 )
    // const fedeRole   = p.players.find( pl => pl.rindex === 0 )

    players = players.map((p, pi) => {
      const gindex = groups.findIndex((g) =>
        g.players.find((gp) => gp._id === p._id)
      );
      p.group = 1 + gindex;
      p.count = (p.results.length / 4) * 100 + '%';
      const ml =
        process.env.REACT_APP_GAME_URL +
        '?i=' +
        p.game_id +
        '&e=' +
        p.email +
        '&t=' +
        p.token +
        '&k=1';
      p.magiclink =
        user.from !== 'lti-hbp' ? (
          <a href={ml} target='_blank'>
            <code style={{ maxWidth: '100px' }}>{ml}</code>
          </a>
        ) : (
          'N/A'
        );
      return p;
    });

    // Validate that all the players have a valid email
    const validNewPlayers =
      this.state.new_players.length === 0
        ? false
        : this.state.new_players.every((pl) => {
            return validateEmail(pl.email);
          });
    // Validate if there are repeated players
    const repeatedPlayers = this.state.new_players.filter((pl, pli) => {
      return (
        players.find((p) => p.email === pl.email) ||
        this.state.new_players.find(
          (p, pi) => p.email === pl.email && pli !== pi
        )
      );
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Player Management{' '}
          <small
            className='badge badge-pill badge-success'
            style={{
              fontSize: '12px',
              padding: '5px 8px',
              verticalAlign: 'middle',
            }}>
            {players.length} players
          </small>
          <small className='float-right'>
            {user.from !== 'lti-hbp' && (
              <button
                className='btn btn-outline-primary'
                onClick={this.addPairModal}>
                Add Player
              </button>
            )}
            <button
              className='btn btn-outline-primary ml-3'
              onClick={(e) => window.location.reload()}>
              <FontAwesomeIcon icon='redo-alt' />
            </button>
          </small>
        </h2>
        <List
          labels={['Name', 'Surname', 'Email', 'Progress', 'MagicLink']}
          fields={['name', 'lastname', 'email', 'count', 'magiclink']}
          data={players}
        />

        <Modal
          isOpen={this.state.pair_modal}
          toggle={this.addPairModal}
          centered={true}
          size={'lg'}>
          <ModalHeader>Add Player</ModalHeader>
          <ModalBody className='text-center'>
            <p>
              Enter the player information and click the <b>"Add"</b> button.
            </p>
            <AddPlayer
              onChange={this.handleNewPlayers}
              roles={["Federico's", 'Zephyr']}
            />
            {repeatedPlayers.length > 0 && (
              <div className='alert alert-danger mt-4'>
                The following players are already in this session:{' '}
                <b>{repeatedPlayers.map((p) => p.email).join(', ')}</b>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-light' onClick={this.addPairModal}>
              Cancel
            </button>
            <button
              className='btn btn-outline-primary'
              onClick={this.submitNewPlayers}
              disabled={!validNewPlayers || repeatedPlayers.length > 0}>
              Add
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game, authentication } = state;
  const { user } = authentication;
  return {
    user,
    game,
    player,
  };
}

const connectedPlayersPage = connect(mapStateToProps)(Players);
export { connectedPlayersPage as GamePlayers };
