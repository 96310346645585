import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../../actions';

// import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
      tip: false,
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
    this.toggleTip = this.toggleTip.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  toggleTip() {
    this.setState({ tip: !this.state.tip });
  }

  render() {
    const { game } = this.props;
    const players = game.data.players || [];

    const playersList = players.map((player) => {
      const net_revenue = player.results.reduce(
        (acc, r) => acc + (r.final_revenue < 0 ? 0 : r.final_revenue),
        0
      );
      const performance = player.results.reduce((acc, r) => {
        const score =
          (r.cal_selection.correct ? 1 : 0) +
          (r.cal_optimal_price.correct ? 1 : 0) +
          (r.cal_optimal_delivery.correct ? 1 : 0);
        return acc + score / 3;
      }, 0);
      const total_performance = performance / player.results.length;
      return {
        name: player.name,
        lastname: player.lastname,
        email: player.email,
        revenue: {
          value: net_revenue,
          label: numeral(net_revenue).format('$0,0'),
        },
        performance: {
          value: total_performance,
          label: numeral(total_performance).format('0,0%'),
        },
      };
    });

    // console.log({ playersList });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3' style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Name',
              'Surname',
              'Email',
              'Net Revenue',
              <span>
                Performance{' '}
                <FontAwesomeIcon
                  id='tip'
                  icon='question-circle'
                  size='1x'
                  className='icon text-dark'
                />
              </span>,
            ]}
            fields={['name', 'lastname', 'email', 'revenue', 'performance']}
            sortables={['name', 'lastname', 'email', 'revenue', 'performance']}
            data={playersList}
            onRowClick={this.rowClick}
          />
          <Tooltip isOpen={this.state.tip} target='tip' toggle={this.toggleTip}>
            Performance is defined as the number of correct responses divided by
            the total number of responses submitted.
          </Tooltip>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
